export default {
    name: 'v-layout-nav',
    data() {
      return {
        dialogs: {
          logout: false,
        },
        drawers: {
          menu: false,
        },
        mobilePropsTitle:{
          marginTop:'0px !important'
        },
        nomobilePropsTitle:{
          marginTop:'110px !important'
        }
      }
    },
    computed: {
      isMobileTitle(){
        if(this.$vuetify.breakpoint.mdAndDown){
          return this.mobilePropsTitle
        }else{
          return this.nomobilePropsTitle
        }
      }
    },
    methods: {
  
      /**
       * 
       */
      handleLogoutClick() {
        this.dialogs.logout = true
      },
  
      /**
       * 
       */
      async handleLogoutAcceptClick() {
  
        // reset auth module
        await this.$store.dispatch('AuthModule/reset')
  
        // reset provider module
        await this.$store.dispatch('ProviderModule/reset')
  
        // reset user module
        await this.$store.dispatch('UserModule/reset')
  
        // close dialog
        this.dialogs.logout = false
  
        // redirect
        this.$router.push('/')
      },
  
      /**
       * 
       */
      handleLogoutCancelClick() {
        this.dialogs.logout = false
      },
    }
  }